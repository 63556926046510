import type { ListItemProps, OptionsSource } from './types';

export const transformData = (options: OptionsSource[]): ListItemProps[] => {
  const results: ListItemProps[] = [];

  options.forEach((option) => {
    results.push({
      label: option.label,
      value: option.value,
    });

    if (option.children) {
      const parentId = results.length - 1;

      option.children.forEach((item) => {
        results.push({
          label: item.label,
          value: item.value,
          parentId,
          all: item.value === option.value,
        });
      });
    }
  });

  return results;
};
