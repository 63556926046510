import type { ReactNode } from 'react';
import { useState } from 'react';
import { clsx } from 'clsx';

import { Accordion } from 'src/components/Accordion';

import {
  getItem,
  getItemParent,
  isChildSelected,
  isRootElement,
  isSelected,
} from '../hooks/useMultiselect';

import { PepitaCheckbox } from 'src/libs/ui/pepita-checkbox';

import type { ListItemProps, ListProps } from '../types';

import { Styles } from '../styles';

import { AccordionLabel } from './AccordionLabel';
import { ListItem } from './ListItem';

import css from '../styles.module.scss';

type SectionWithChildren = {
  item: ListItemProps;
  accordionLabel?: ReactNode;
  children?: ReactNode;
};

export function List({
  items,
  selected,
  onClick,
  listRef,
  footer,
  forceOptionClosed,
}: ListProps) {
  const firstSelectedItem = getItem(items, selected[0]);
  const parent = getItemParent(items, firstSelectedItem) || firstSelectedItem;

  const accordionItems: SectionWithChildren[] = items
    .filter(isRootElement)
    .map((item) => {
      const children = items.filter((child) => {
        if (child.parentId === undefined) {
          return;
        }

        return items[child.parentId] === item;
      });

      if (children.length) {
        const subList = children.map((child, index) => {
          return (
            <ListItem
              key={index}
              item={{
                ...child,
                label: (
                  <PepitaCheckbox
                    value={child.value}
                    checked={isSelected(selected, child.value)}
                    readOnly
                  >
                    {child.label}
                  </PepitaCheckbox>
                ),
              }}
              onClick={(event) => onClick(event, child)}
            />
          );
        });

        return {
          item,
          accordionLabel: (
            <AccordionLabel
              item={item}
              checked={isChildSelected(selected, children)}
              onClick={(e) => onClick(e, item)}
            />
          ),
          children: <ul className={css[Styles.list]}>{subList}</ul>,
        };
      }

      return { item };
    });

  // Controlled Accordion with toggle
  const [multiOpen, setMultiOpen] = useState<number | null>(() => {
    if (forceOptionClosed || !parent) return null;

    return accordionItems.findIndex(
      (section) => parent.value === section.item.value
    );
  });

  const accordion = (
    <Accordion
      open={multiOpen}
      customClass={clsx(
        css[Styles.typologyAccordion],
        css[Styles.typologyScrollableList],
        footer && Styles.selectScrollableList
      )}
      onChange={(i) => {
        setMultiOpen((s) => (s === i ? null : i));
      }}
      items={accordionItems.map((section) => ({
        labelCustomClass: clsx(
          css[Styles.typologyAccordionLabel],
          parent &&
            parent.value === section.item.value &&
            css[Styles.isSelected]
        ),
        text: section.children ? (
          section.accordionLabel
        ) : (
          <AccordionLabel
            item={section.item}
            checked={isSelected(selected, section.item.value)}
            onClick={onClick}
          />
        ),
        content: section.children ? section.children : null,
      }))}
    />
  );

  return <div ref={listRef}>{accordion}</div>;
}
