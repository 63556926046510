import type { ListItemTemplateProps } from '../types';

import { Styles } from '../styles';

export function ListItem({ item, onClick }: ListItemTemplateProps) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onClick={(e) => {
        e.preventDefault();
        onClick(e, item);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          onClick(event, item);
        }
      }}
      className={Styles.listItem}
    >
      {item.label}
    </li>
  );
}
