import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';

import type { ModalTemplateProps } from '../types';

import { Styles } from '../styles';

export function ModalTemplate({
  title,
  children,
  handleConfirm,
  handleClose,
}: ModalTemplateProps) {
  const { trans } = useTranslations();

  return (
    <PepitaDialog
      onClose={handleClose}
      content={
        <div className={clsx(Styles.selectMenuMobile, Styles.isOpen)}>
          {children}
        </div>
      }
      size={PepitaDialogFrameType.FULL}
      contentSize={PepitaDialogContentFrameType.FULL}
      contentNotScrollable
      title={title}
      footer={
        <PepitaButton variant="accent" onClick={() => handleConfirm()}>
          {trans('act_confirm')}
        </PepitaButton>
      }
    />
  );
}
