import { Styles } from '../styles';

import css from '../styles.module.scss';

type LabelTextPropTypes = {
  label: string;
  childrenLabel?: string;
};

export function LabelText({ label, childrenLabel }: LabelTextPropTypes) {
  return (
    <>
      {label}
      {childrenLabel && (
        <span className={css[Styles.typologyChildrenLabel]}>
          {childrenLabel}
        </span>
      )}
    </>
  );
}
