import type { Dispatch } from 'react';
import { useEffect, useReducer } from 'react';

import type { ListItemProps } from '../types';

export type MultiselectState = {
  selection?: string[];
  draft?: string[];
};

type Actions =
  | {
      type: 'ADD_ITEM' | 'REMOVE_ITEM' | 'OVERWRITE_SELECTION';
      payload?: string;
    }
  | { type: 'COMMIT' };

export const getItemParent = (
  options: ListItemProps[],
  item?: ListItemProps
) => {
  if (!item?.parentId) {
    return;
  }

  return options[item.parentId];
};

export const isRootElement = (item: ListItemProps) =>
  typeof item.parentId === 'undefined';

export const isSelected = (selection: string[] = [], item: string) => {
  return selection.some((v) => v === item);
};

export const isChildSelected = (
  selection: string[] = [],
  children: ListItemProps[]
) =>
  selection.some((sel) =>
    Boolean(children.find((child) => sel === child.value))
  );

export const getItem = (options: ListItemProps[], value: string) =>
  options.find((item) => item.value === value && !item.all);

export const useMultiselect = (
  options: ListItemProps[],
  defaultValue?: string
) => {
  const [state, dispatch]: [MultiselectState, Dispatch<Actions>] = useReducer(
    (state, action: Actions) => {
      let draft = state.draft || state.selection;

      switch (action.type) {
        case 'ADD_ITEM': {
          const item = action.payload
            ? getItem(options, action.payload)
            : undefined;

          const firstSelected =
            draft && draft.length && getItem(options, draft[0]);

          if (
            (item && isRootElement(item)) ||
            !firstSelected ||
            firstSelected.parentId !== item?.parentId
          ) {
            return {
              ...state,
              draft: [item?.value],
            };
          }

          const siblings = options.filter(
            (v) => !v.all && v.parentId === item?.parentId
          );

          draft = draft.concat(item?.value);

          if (siblings.length === draft.length) {
            draft = [getItemParent(options, item)?.value];
          }

          return {
            ...state,
            draft,
          };
        }

        case 'REMOVE_ITEM': {
          const item = action.payload
            ? getItem(options, action.payload)
            : undefined;

          draft = draft.filter((v) => v !== action.payload);

          if (draft.length === 0) {
            const rootElement = getItemParent(options, item) || item;

            return {
              ...state,
              draft: [rootElement?.value],
            };
          }

          return {
            ...state,
            draft: draft.filter((v) => v !== item?.value),
          };
        }

        case 'OVERWRITE_SELECTION': {
          return {
            ...state,
            selection: action.payload?.split(','),
            draft: null,
          };
        }

        case 'COMMIT':
          return {
            ...state,
            selection: draft,
            draft: null,
          };
      }
    },
    { selection: defaultValue && defaultValue.split(',') }
  );

  const selection = state.selection;
  const draft = state.draft || selection;

  function toggleItem(item: string) {
    dispatch({
      type: isSelected(draft, item) ? 'REMOVE_ITEM' : 'ADD_ITEM',
      payload: item,
    });
  }

  function commit() {
    dispatch({
      type: 'COMMIT',
    });
  }

  useEffect(() => {
    // Overwriting selection only when the value has not been set from
    // inside
    if (state.selection && defaultValue !== state.selection.join(',')) {
      dispatch({
        type: 'OVERWRITE_SELECTION',
        payload: defaultValue || options[0].value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return {
    selection,
    draft,
    toggleItem,
    commit,
  };
};
