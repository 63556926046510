//@ts-expect-error
import categories from '<product-categories>';

export type Categories = {
  COMMERCIAL: boolean;
  ROOMS: boolean;
  NEW_BUILDING: boolean;
  GARAGE: boolean;
  PALACE: boolean;
  COWORKING: boolean;
  WAREHOUSES: boolean;
  SHEDS: boolean;
  LAND: boolean;
  HOTEL: boolean;
};

export const isCategoryEnabled = (key: keyof Categories) => {
  return categories[key];
};
