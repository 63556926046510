import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { DropDown } from './components/DropDown';
import { DropdownContent } from './components/DropdownContent';
import { Label } from './components/Label';

import { useDropdown } from './hooks/useDropdown';
import { useLabel } from './hooks/useLabel';
import { useMultiselect } from './hooks/useMultiselect';

import type { ListItemProps, MultiSelectProps, OptionsSource } from './types';

import { transformData } from './utils';

import { Styles } from './styles';

import css from './styles.module.scss';

const TypologyMultiselect = forwardRef<HTMLDivElement, MultiSelectProps>(
  (
    {
      fieldLabel,
      customClass,
      customControlClasses,
      value: defaultValue,
      options: rawOptions,
      onChange,
      footer,
      forceOptionClosed = false,
      icon,
    },
    multiselectRef
  ) => {
    const { trans } = useTranslations();

    const options = useMemo(
      () => transformData(rawOptions as OptionsSource[]),
      [rawOptions]
    ) as ListItemProps[];

    // refs
    const multiselectRefDefault = useRef<HTMLDivElement>(null);
    const dropdown = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);

    multiselectRef = multiselectRef || multiselectRefDefault;

    const { fullscreenMode, isOpen, close } = useDropdown(
      multiselectRef,
      labelRef
    );

    const multiselect = useMultiselect(options, defaultValue);

    const { label, childrenLabel } = useLabel(options, multiselect.selection);

    useEffect(() => {
      onChange(multiselect.selection);
    }, [multiselect.selection]);

    return (
      <div
        ref={multiselectRef}
        className={clsx(
          css[Styles.typologySelect],
          Styles.select,
          customClass,
          isOpen && Styles.isOpen,
          isOpen && Styles.hasFocused
        )}
      >
        <Label
          labelRef={labelRef}
          label={label}
          childrenLabel={childrenLabel}
          customControlClasses={customControlClasses}
          fieldLabel={fieldLabel}
          icon={icon}
        />
        <DropDown
          title={trans('lbl_typology', { capitalize: true })}
          isOpen={isOpen}
          isMobile={fullscreenMode}
          handleConfirm={() => {
            multiselect.commit();
            close();
          }}
          handleClose={() => close()}
        >
          <DropdownContent
            multiselect={multiselect}
            options={options}
            dropdown={dropdown}
            isMobile={fullscreenMode}
            footer={footer}
            forceOptionClosed={forceOptionClosed}
          />
        </DropDown>
      </div>
    );
  }
);

TypologyMultiselect.displayName = 'TypologyMultiselect';

export { TypologyMultiselect };
