import { PepitaRadio } from 'src/libs/ui/pepita-radio';

import type { AccordionLabelProps } from '../types';

import { Styles } from '../styles';

import css from '../styles.module.scss';

export function AccordionLabel({
  item,
  checked,
  onClick,
}: AccordionLabelProps) {
  return (
    <div
      role="button"
      className={css[Styles.typologyAccordionLabelContent]}
      onClick={(e) => {
        e.preventDefault();
        onClick(e, item);
      }}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          onClick(event, item);
        }
      }}
    >
      <PepitaRadio
        value={item.value}
        checked={checked}
        controlCustomClass={css[Styles.typologyAccordionLabelInput]}
        readOnly
      >
        {item.label}
      </PepitaRadio>
    </div>
  );
}
