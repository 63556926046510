import type { SyntheticEvent } from 'react';
import { useRef } from 'react';
import { clsx } from 'clsx';

import type { DropdownContentProps, ListItemProps } from '../types';

import { Styles } from '../styles';

import { List } from './List';

export function DropdownContent({
  multiselect,
  options,
  dropdown,
  isMobile,
  footer,
  forceOptionClosed,
}: DropdownContentProps) {
  const list = useRef<HTMLDivElement>(null);

  // select item on desktop
  const selectItem = (_: SyntheticEvent, item: ListItemProps) => {
    multiselect.toggleItem(item.value);

    if (!isMobile) {
      multiselect.commit();
    }
  };

  return (
    <>
      <div
        className={clsx(
          isMobile
            ? Styles.selectMenuMobile
            : [Styles.selectMenu, Styles.typologyMenu],
          footer && Styles.selectMenuWithFooter,
          Styles.isOpen
        )}
        hidden={!open}
        ref={dropdown}
        data-cy={'typology-dropdown'}
      >
        <List
          items={options}
          selected={multiselect.draft || []}
          onClick={selectItem}
          listRef={list}
          footer={footer}
          forceOptionClosed={forceOptionClosed}
        />
        {footer && <div className={Styles.selectFooter}>{footer}</div>}
      </div>
    </>
  );
}
