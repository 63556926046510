import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { CATEGORIES } from 'src/constants/typologies';

import type { typologyEntity } from 'src/types/lookup';

const immobiliareTypologies = {
  [CATEGORIES.RESIDENZIALE]: [
    {
      nome: 'db_typology.id_4', // i18n-extract-keys ["db_typology.id_4"]
      idTipologia: '4',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'appartamenti',
    },

    {
      nome: 'db_typology.id_5', // i18n-extract-keys ["db_typology.id_5"]
      idTipologia: '5',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'attici',
    },

    {
      nome: 'db_typology.id_7', // i18n-extract-keys ["db_typology.id_7"]
      idTipologia: '7',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'case_indipendenti',
    },

    {
      nome: 'db_typology.id_31', // i18n-extract-keys ["db_typology.id_31"]
      idTipologia: '31',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'loft',
    },

    {
      nome: 'db_typology.id_11', // i18n-extract-keys ["db_typology.id_11"]
      idTipologia: '11',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'rustici',
    },

    {
      nome: 'db_typology.id_12', // i18n-extract-keys ["db_typology.id_12"]
      idTipologia: '12',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'villa',
    },

    {
      nome: 'db_typology.id_13', // i18n-extract-keys ["db_typology.id_13"]
      idTipologia: '13',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'villette',
    },

    {
      nome: 'db_typology.id_14', // i18n-extract-keys ["db_typology.id_14"]
      idTipologia: '14',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'immobiliare',
    },
  ],
  [CATEGORIES.NUOVE_COSTRUZIONI]: [
    {
      nome: 'db_typology.id_54', // i18n-extract-keys ["db_typology.id_54"]
      idTipologia: '54',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'appartamenti',
    },

    {
      nome: 'db_typology.id_85', // i18n-extract-keys ["db_typology.id_85"]
      idTipologia: '85',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'attici',
    },

    {
      nome: 'db_typology.id_60', // i18n-extract-keys ["db_typology.id_60"]
      idTipologia: '60',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'loft',
    },

    {
      nome: 'db_typology.id_58', // i18n-extract-keys ["db_typology.id_58"]
      idTipologia: '58',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'ville',
    },

    {
      nome: 'db_typology.id_57', // i18n-extract-keys ["db_typology.id_57"]
      idTipologia: '57',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'box_auto',
    },

    {
      nome: 'db_typology.id_56', // i18n-extract-keys ["db_typology.id_56"]
      idTipologia: '56',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'ufficio',
    },

    {
      nome: 'db_typology.id_55', // i18n-extract-keys ["db_typology.id_55"]
      idTipologia: '55',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'negozio',
    },

    {
      nome: 'db_typology.id_61', // i18n-extract-keys ["db_typology.id_61"]
      idTipologia: '61',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'magazzino',
    },

    {
      nome: 'db_typology.id_59', // i18n-extract-keys ["db_typology.id_59"]
      idTipologia: '59',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'capannone',
    },
  ],
  [CATEGORIES.TERRENI]: [
    {
      nome: 'db_typology.id_106', // i18n-extract-keys ["db_typology.id_106"]
      idTipologia: '106',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_agricolo',
    },
    {
      nome: 'db_typology.id_107', // i18n-extract-keys ["db_typology.id_107"]
      idTipologia: '107',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_edificabile',
    },
  ],
};

const indomioEsTypologies = {
  [CATEGORIES.RESIDENZIALE]: [
    {
      nome: 'db_typology.id_4', // i18n-extract-keys ["db_typology.id_4"]
      idTipologia: '4',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'appartamenti',
    },

    {
      nome: 'db_typology.id_5', // i18n-extract-keys ["db_typology.id_5"]
      idTipologia: '5',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'attici',
    },

    {
      nome: 'db_typology.id_11', // i18n-extract-keys ["db_typology.id_11"]
      idTipologia: '11',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'rustici',
    },

    {
      nome: 'db_typology.id_12', // i18n-extract-keys ["db_typology.id_12"]
      idTipologia: '12',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'villa',
    },

    {
      nome: 'db_typology.id_13', // i18n-extract-keys ["db_typology.id_13"]
      idTipologia: '13',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'villette',
    },
  ],
  [CATEGORIES.TERRENI]: [
    {
      nome: 'db_typology.id_106', // i18n-extract-keys ["db_typology.id_106"]
      idTipologia: '106',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_agricolo',
    },
    {
      nome: 'db_typology.id_107', // i18n-extract-keys ["db_typology.id_107"]
      idTipologia: '107',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_edificabile',
    },
  ],
};

const indomioGrTypologies = isFeatureEnabled('NEW_INDOMIOGR_REDIDENTIAL_LISTS')
  ? {
      [CATEGORIES.RESIDENZIALE]: [
        {
          nome: 'db_typology.id_4', // i18n-extract-keys ["db_typology.id_4"]
          idTipologia: '4',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'diamerismata',
        },
        {
          nome: 'db_typology.id_113', // i18n-extract-keys ["db_typology.id_113"]
          idTipologia: '113',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'gkarsonieres',
        },
        {
          nome: 'db_typology.id_30', // i18n-extract-keys ["db_typology.id_30"]
          idTipologia: '30',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'mezonetes',
        },
        {
          nome: 'db_typology.id_7', // i18n-extract-keys ["db_typology.id_7"]
          idTipologia: '7',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'monokatoikies',
        },
        {
          nome: 'db_typology.id_12', // i18n-extract-keys ["db_typology.id_12"]
          idTipologia: '12',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'viles',
        },
        {
          nome: 'db_typology.id_31', // i18n-extract-keys ["db_typology.id_31"]
          idTipologia: '31',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'lofts',
        },
      ],
      [CATEGORIES.TERRENI]: [
        {
          nome: 'db_typology.id_106', // i18n-extract-keys ["db_typology.id_106"]
          idTipologia: '106',
          fkCategoria: '24',
          attivo: '3',
          keyurl_im: 'agrotemaxia',
        },
        {
          nome: 'db_typology.id_107', // i18n-extract-keys ["db_typology.id_107"]
          idTipologia: '107',
          fkCategoria: '24',
          attivo: '3',
          keyurl_im: 'oikopeda',
        },
        {
          nome: 'db_typology.id_111', // i18n-extract-keys ["db_typology.id_111"]
          idTipologia: '111',
          fkCategoria: '24',
          attivo: '3',
          keyurl_im: 'nisia',
        },
      ],
    }
  : {
      [CATEGORIES.RESIDENZIALE]: [
        {
          nome: 'db_typology.id_4', // i18n-extract-keys ["db_typology.id_4"]
          idTipologia: '4',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'appartamenti',
        },

        {
          nome: 'db_typology.id_5', // i18n-extract-keys ["db_typology.id_5"]
          idTipologia: '5',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'attici',
        },

        {
          nome: 'db_typology.id_12', // i18n-extract-keys ["db_typology.id_12"]
          idTipologia: '12',
          fkCategoria: '1',
          attivo: '3',
          keyurl_im: 'villa',
        },
      ],
    };

const ImmotopLuTypologies = {
  [CATEGORIES.RESIDENZIALE]: [
    {
      nome: 'db_typology.id_4', // i18n-extract-keys ["db_typology.id_4"]
      idTipologia: '4',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'appartamenti',
    },
    {
      nome: 'db_typology.id_5', // i18n-extract-keys ["db_typology.id_5"]
      idTipologia: '5',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'attici',
    },
    {
      nome: 'db_typology.id_7', // i18n-extract-keys ["db_typology.id_7"]
      idTipologia: '7',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'case_indipendenti',
    },
    {
      nome: 'db_typology.id_114', // i18n-extract-keys ["db_typology.id_114"]
      idTipologia: '114',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'duplex',
    },
    {
      nome: 'db_typology.id_31', // i18n-extract-keys ["db_typology.id_31"]
      idTipologia: '31',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'loft',
    },
    {
      nome: 'db_typology.id_11', // i18n-extract-keys ["db_typology.id_11"]
      idTipologia: '11',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'rustici',
    },
    {
      nome: 'db_typology.id_113', // i18n-extract-keys ["db_typology.id_113"]
      idTipologia: '113',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'studio',
    },
    {
      nome: 'db_typology.id_12', // i18n-extract-keys ["db_typology.id_12"]
      idTipologia: '12',
      fkCategoria: '1',
      attivo: '3',
      keyurl_im: 'villa',
    },
  ],
  [CATEGORIES.NUOVE_COSTRUZIONI]: [
    {
      nome: 'db_typology.id_54', // i18n-extract-keys ["db_typology.id_54"]
      idTipologia: '54',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'appartamenti',
    },
    {
      nome: 'db_typology.id_85', // i18n-extract-keys ["db_typology.id_85"]
      idTipologia: '85',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'attici',
    },
    {
      nome: 'db_typology.id_60', // i18n-extract-keys ["db_typology.id_60"]
      idTipologia: '60',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'loft',
    },
    {
      nome: 'db_typology.id_58', // i18n-extract-keys ["db_typology.id_58"]
      idTipologia: '58',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'ville',
    },
    {
      nome: 'db_typology.id_57', // i18n-extract-keys ["db_typology.id_57"]
      idTipologia: '57',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'box_auto',
    },
    {
      nome: 'db_typology.id_56', // i18n-extract-keys ["db_typology.id_56"]
      idTipologia: '56',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'ufficio',
    },
    {
      nome: 'db_typology.id_55', // i18n-extract-keys ["db_typology.id_55"]
      idTipologia: '55',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'negozio',
    },
    {
      nome: 'db_typology.id_61', // i18n-extract-keys ["db_typology.id_61"]
      idTipologia: '61',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'magazzino',
    },
    {
      nome: 'db_typology.id_59', // i18n-extract-keys ["db_typology.id_59"]
      idTipologia: '59',
      fkCategoria: '6',
      attivo: '3',
      keyurl_im: 'capannone',
    },
  ],
  [CATEGORIES.TERRENI]: [
    {
      nome: 'db_typology.id_106', // i18n-extract-keys ["db_typology.id_106"]
      idTipologia: '106',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_agricolo',
    },
    {
      nome: 'db_typology.id_107', // i18n-extract-keys ["db_typology.id_107"]
      idTipologia: '107',
      fkCategoria: '24',
      attivo: '3',
      keyurl_im: 'terreno_edificabile',
    },
  ],
};

/**
 * It returns the typologies for the current product
 *
 * @returns a partial of a record of categories and typologyEntity[]
 */
export const getListingTypologies = () => {
  //TODO: sistemare il tipo del ritorno
  if (getProductConfig('name') === 'indomio-es') {
    return indomioEsTypologies as Partial<Record<CATEGORIES, typologyEntity[]>>;
  }

  if (getProductConfig('name') === 'indomio-gr') {
    return indomioGrTypologies as Partial<Record<CATEGORIES, typologyEntity[]>>;
  }

  if (getProductConfig('name') === 'immotop-lu') {
    return ImmotopLuTypologies as Partial<Record<CATEGORIES, typologyEntity[]>>;
  }

  return immobiliareTypologies as Partial<Record<CATEGORIES, typologyEntity[]>>;
};

export const getTypologiesFromCategorySelection = (selection: string[]) => {
  const [_, typologyId] = selection;

  return typologyId || null;
};
