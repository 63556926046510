import { isCategoryEnabled } from 'src/config/categories';
import { isFeatureEnabled } from 'src/config/features-toggle';

import {
  CATEGORIES,
  CONTRACT,
  ROOM_CATEGORIES,
  SHOPS,
  TYPOLOGIES,
  VERTICALS,
} from 'src/constants/typologies';

import type { typologyEntity } from 'src/types/lookup';

import { nonNullable } from './common';

export type CategoryConfigType = {
  label: string;
  value: string;
  order: number;
  contracts: CONTRACT[];
  children: Nullable<
    {
      label: string;
      typologyId: TYPOLOGIES | ROOM_CATEGORIES | SHOPS | string;
      categoryId: CATEGORIES;
    }[]
  >;
  verticals: (typeof VERTICALS)[keyof typeof VERTICALS][];
  categoryId: CATEGORIES;
  typologyId: Nullable<TYPOLOGIES>;
};

const getCommercialTypology = (): string[] => {
  if (isFeatureEnabled('NEW_COMMERCIAL_SEARCH')) {
    if (isFeatureEnabled('LABORATORY_SEARCH_ENABLED')) {
      return [SHOPS.MURA, SHOPS.LABORATORIO, SHOPS.ATTIVITA];
    }

    return [SHOPS.MURA, SHOPS.ATTIVITA];
  }

  return Object.values(SHOPS);
};

const COMMERCIAL_TYPOLOGIES = getCommercialTypology();

const categoriesConfig: CategoryConfigType[] = [
  {
    label: 'category_field_residential', // i18n-extract-keys ["category_field_residential"]
    value: `${CATEGORIES.RESIDENZIALE}_`,
    order: 10,
    contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
    children: [],
    verticals: [
      VERTICALS.RESIDENZIALE,
      VERTICALS.COMMERCIALE,
      VERTICALS.STANZE,
      VERTICALS.NUOVE_COSTRUZIONI,
      VERTICALS.AGENZIA,
      VERTICALS.TERRENI, // getLandCategoryId
      VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
      VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
      VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
      VERTICALS.CAPANNONI, // getShedCategoryId
      VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
      VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
    ],
    categoryId: CATEGORIES.RESIDENZIALE,
    typologyId: null,
  },
];

export const getCategoriesConfig = (
  typologiesByCategories: Partial<Record<CATEGORIES, typologyEntity[]>>
) => {
  const config = [...categoriesConfig];

  const residentialConfig = config.find(
    (config) => config.categoryId === CATEGORIES.RESIDENZIALE
  ) as CategoryConfigType;

  residentialConfig.children =
    typologiesByCategories[CATEGORIES.RESIDENZIALE]
      ?.map((typologyEntity) =>
        typologyEntity.idTipologia !== TYPOLOGIES.ALTRO
          ? {
              label: typologyEntity.nome,
              typologyId: typologyEntity.idTipologia,
              categoryId: typologyEntity.fkCategoria,
            }
          : null
      )
      .filter(nonNullable) || [];

  if (isCategoryEnabled('GARAGE')) {
    config.push({
      label: 'category_field_garage', // i18n-extract-keys ["category_field_garage"]
      value: `${CATEGORIES.GARAGE_POSTI_AUTO}_`,
      order: 40,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.GARAGE_POSTI_AUTO,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('PALACE')) {
    config.push({
      label: 'category_field_palace', // i18n-extract-keys ["category_field_palace"]
      value: `${CATEGORIES.PALAZZI_EDIFICI}_`,
      order: 50,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.PALAZZI_EDIFICI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('COWORKING')) {
    config.push({
      label: 'category_field_offices', // i18n-extract-keys ["category_field_offices"]
      value: `${CATEGORIES.UFFICI_COWORKING}_`,
      order: 60,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.UFFICI_COWORKING,
      typologyId: null,
    });
  }

  if (
    isCategoryEnabled('COMMERCIAL') &&
    !isFeatureEnabled('COMMERCIAL_SEARCH_AS_CATEGORIES')
  ) {
    config.push({
      label: 'category_field_stores', // i18n-extract-keys ["category_field_stores"]
      value: `${CATEGORIES.NEGOZI_LOCALI_COMMERCIALI}_`,
      order: 70,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      // i18n-extract-keys ["stores_type_1", "stores_type_2", "stores_type_3"]
      // i18n-extract-keys ["stores_type_4", "stores_type_5", "stores_type_6"]
      // i18n-extract-keys ["stores_type_7"]
      children: COMMERCIAL_TYPOLOGIES.map((shopValue: string) => ({
        label: `stores_type_${shopValue}`,
        typologyId: shopValue,
        categoryId: CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      })),
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      typologyId: null,
    });
  }

  if (isFeatureEnabled('COMMERCIAL_SEARCH_AS_CATEGORIES')) {
    config.push({
      label: 'category_field_stores', // i18n-extract-keys ["category_field_stores"]
      value: `${CATEGORIES.NEGOZI_LOCALI_COMMERCIALI}_${SHOPS.MURA}`,
      order: 70,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      typologyId: null,
    });

    config.push({
      label: 'category_field_activities_and_licenses', // i18n-extract-keys ["category_field_activities_and_licenses"]
      value: `${CATEGORIES.NEGOZI_LOCALI_COMMERCIALI}_${SHOPS.ATTIVITA}`,
      order: 70,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('HOTEL')) {
    config.push({
      label: 'category_field_hotel', // i18n-extract-keys ["category_field_hotel"]
      value: `${CATEGORIES.PALAZZI_EDIFICI}_${TYPOLOGIES.PALAZZO_HOTEL}`,
      order: 71,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.PALAZZI_EDIFICI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('WAREHOUSES')) {
    config.push({
      label: 'category_field_warehouses', // i18n-extract-keys ["category_field_warehouses"]
      value: `${CATEGORIES.MAGAZZINI_DEPOSITI}_`,
      order: 80,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.MAGAZZINI_DEPOSITI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('SHEDS')) {
    config.push({
      label: 'category_field_sheds', // i18n-extract-keys ["category_field_sheds"]
      value: `${CATEGORIES.CAPANNONI}_`,
      order: 90,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children: [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.CAPANNONI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('LAND')) {
    config.push({
      label: 'category_field_lands', // i18n-extract-keys ["category_field_lands"]
      value: `${CATEGORIES.TERRENI}_`,
      order: 100,
      contracts: [CONTRACT.VENDITA, CONTRACT.AFFITTO, CONTRACT.ASTE],
      children:
        typologiesByCategories[CATEGORIES.TERRENI]
          ?.map((typologyEntity) => {
            return {
              label: typologyEntity.nome,
              typologyId: typologyEntity.idTipologia,
              categoryId: typologyEntity.fkCategoria,
            };
          })
          .filter(Boolean) || [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
        VERTICALS.ASTE,
      ],
      categoryId: CATEGORIES.TERRENI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('NEW_BUILDING')) {
    config.push({
      label: 'category_field_new_constructions', // i18n-extract-keys ["category_field_new_constructions"]
      value: `${CATEGORIES.NUOVE_COSTRUZIONI}_`,
      order: 20,
      contracts: [CONTRACT.VENDITA],
      children:
        typologiesByCategories[CATEGORIES.NUOVE_COSTRUZIONI]
          ?.map((typologyEntity) => {
            return {
              label: typologyEntity.nome,
              typologyId: typologyEntity.idTipologia,
              categoryId: typologyEntity.fkCategoria,
            };
          })
          .filter(Boolean) || [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
      ],
      categoryId: CATEGORIES.NUOVE_COSTRUZIONI,
      typologyId: null,
    });
  }

  if (isCategoryEnabled('ROOMS')) {
    const isOnlyCompleteEnabled = isFeatureEnabled(
      'ONLY_COMPLETE_BEDROOM_SEARCH_ENABLED'
    );

    config.push({
      label: isOnlyCompleteEnabled
        ? 'category_field_only_rooms'
        : 'category_field_rooms', // i18n-extract-keys ["category_field_rooms", "category_field_only_rooms"]
      value: `${CATEGORIES.STANZE}_`,
      order: 30,
      contracts: [CONTRACT.AFFITTO],
      children: !isOnlyCompleteEnabled
        ? [
            {
              label: 'category_field_bed', // i18n-extract-keys ["category_field_bed"]
              typologyId: ROOM_CATEGORIES.POSTO_LETTO,
              categoryId: CATEGORIES.STANZE,
            },
            {
              label: 'category_field_entire_room', // i18n-extract-keys ["category_field_entire_room"]
              typologyId: ROOM_CATEGORIES.COMPLETA,
              categoryId: CATEGORIES.STANZE,
            },
          ]
        : [],
      verticals: [
        VERTICALS.RESIDENZIALE,
        VERTICALS.COMMERCIALE,
        VERTICALS.STANZE,
        VERTICALS.NUOVE_COSTRUZIONI,
        VERTICALS.AGENZIA,
        VERTICALS.TERRENI, // getLandCategoryId
        VERTICALS.MAGAZZINI_DEPOSITI, // getWareHousesCategoryId
        VERTICALS.PALAZZI_EDIFICI, // getPalacesCategoryId
        VERTICALS.GARAGE_POSTI_AUTO, // getGarageCategoryId
        VERTICALS.CAPANNONI, // getShedCategoryId
        VERTICALS.UFFICI_COWORKING, //getOfficeCategoryId
        VERTICALS.NEGOZI_LOCALI_COMMERCIALI, //getShopCategoryId
        VERTICALS.ATTIVITA,
      ],
      categoryId: CATEGORIES.STANZE,
      typologyId: null,
    });
  }

  config.sort((conf1, conf2) => conf1.order - conf2.order);

  return config;
};
