import type { DropDownProps } from '../types';

import { ModalTemplate } from './Modal';

export function DropDown({
  title,
  isOpen,
  isMobile,
  children,
  handleConfirm,
  handleClose,
}: DropDownProps) {
  if (isOpen) {
    if (isMobile) {
      return (
        <ModalTemplate
          title={title}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
        >
          {children}
        </ModalTemplate>
      );
    }

    return <> {children} </>;
  }

  return null;
}
