import type { Ref } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { Styles } from '../styles';

import { LabelText } from './LabelText';

type LabelPropTypes = {
  labelRef: Ref<HTMLDivElement>;
  label: string;
  customControlClasses?: string;
  fieldLabel?: string;
  childrenLabel?: string;
  icon?: PepitaIconProps;
};

export const Label = memo(function Label({
  labelRef,
  label,
  customControlClasses,
  fieldLabel,
  childrenLabel,
  icon,
}: LabelPropTypes) {
  return (
    <div
      role="button"
      className={clsx(
        Styles.selectControl,
        customControlClasses,
        'js-multiselect-control'
      )}
      data-placeholder="tipologia"
      ref={labelRef}
      data-label={fieldLabel}
    >
      {icon ? (
        <span className={Styles.iconControl}>
          <PepitaIcon customClass={Styles.icon} {...icon} />
          <span className={Styles.iconText}>
            <LabelText label={label} childrenLabel={childrenLabel} />
          </span>
        </span>
      ) : (
        <span>
          <LabelText label={label} childrenLabel={childrenLabel} />
        </span>
      )}
      <PepitaIcon name="arrow-right" customClass={Styles.selectArrow} />
    </div>
  );
});
