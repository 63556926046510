export enum Styles {
  typologySelect = 'in-typologySelect',
  select = 'nd-select',
  selectControl = 'nd-select__control',
  list = 'nd-stack',
  selectScrollableList = 'nd-select__scrollableList',
  typologyScrollableList = 'in-typologySelect__scrollableList--big',
  listItem = 'nd-stackItem',
  isSelected = 'is-selected',
  selectArrow = 'nd-select__arrow',
  isOpen = 'is-open',
  hasFocused = 'has-focused',
  selectMenu = 'nd-select__menu',
  typologyMenu = 'in-typologySelect__menu',
  selectMenuMobile = 'nd-select__menuMobile',
  selectMenuWithFooter = 'nd-select__menu--withFooter',
  selectFooter = 'nd-select__footer',
  typologyAccordion = 'in-typologySelect__accordion',
  typologyAccordionLabel = 'in-typologySelect__accordionLabel',
  typologyAccordionLabelContent = 'in-typologySelect__accordionLabelContent',
  typologyAccordionLabelInput = 'in-typologySelect__accordionLabelInput',
  typologyChildrenLabel = 'in-typologySelect__childrenLabel',
  icon = 'nd-select__icon',
  iconControl = 'nd-select__iconControl',
  iconText = 'nd-select__iconText',
}
